import React from "react"

import { Container} from 'reactstrap'
import Link from '../components/link' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-service'
import worldIcon from '../assets/images/world.png' 
import truckAbsolute from '../assets/images/truckAbsolute.png' 
import doorImg from '../assets/images/doorImg.png' 
import doorIcon from '../assets/images/doorIcon.png' 
 
export default () => (
  <Layout>
    <SEO title="Services" />
    <Slider title="SERVICES"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="point-crossed-background" >
		<img className="truckAbsolute" src={truckAbsolute} alt="truck"/> 
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="serviceQuality">{'HIGH QUALITY'}</h4> 
				<p className="serviceQualitySub">{'The best delivery times'}</p>  
			  </div> 
			</div>
		</Container>
	</div>
	<div className="blue-service-background" >
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
				<img className="doorImg" src={doorImg} alt="door to door"/>
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">
				<img className="doorIcon" src={doorIcon} alt="door Icon"/>
				<h4 className="serviceBlueH"><b>DOOR TO DOOR</b> SERVICE</h4> 
				<p className="serviceBlueP">We are specialist in the transportation of goods from point of origin to point of
											 destination, with no need to transload in all the canadian provinces, the 48 states of
											 best delivery times for our customers.<br/><br/>
											 <b>Benefits of working with us.</b> <br/><br/>
											 Both ways transportation inbound and outbound. <br/><br/>
											 We are trained to transport shipments Northbound as well as Sputhbound.<br/>
											 We are an approved FAST lane carrier (Free and Secure Trade to expedite border crossing) since our drivers have this kind of driver license.<br/>
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="blue-intermodal-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock"> 
				<h4 className="serviceBlueH">{'INTERMODAL'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> We have more than 10 years of experience in handling maritime and
											 railway containers in the area of <b>Los Angeles C.A.</b><br/><br/>
											 <b>Benefits of working with us.</b><br/>
											 Certified drivers with TWIC card
											 2013 certified trucks.<br/>
											 Alliances with most shipping lines.<br/>
											 Access permit to shipping terminals.
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="red-logistic-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH">{'LOGISTIC SERVICE'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">  We support our customers with several solutions fot the transportation of their goods,
											  such as route designing, equipment diposition, information on our website, delivery  
											  date appointments or warehousing with our sister company. A.G. Warehouse</p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>   
	<div className="blue-bonded-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock"> 
				<h4 className="serviceBlueH">{'BONDED CARRIER'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> Our Bound allows us to transport merchandise from <br/> 
											 one country to another
				</p>
			  </div> 
			</div>
		</Container>
	</div>  
	<div className="red-chemical-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH"><b>CHEMICAL</b> TRANSPORTATION</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> We have all Mexican and American Federal and State permits and licenses for the safe 
											  transportation of non-regulated chemicals to any destination with certified drivers.
				</p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>   
	<div className="blue-warehouse-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">   
				<h4 className="serviceBlueH">{'WAREHOUSING SERVICES - CALEXICO,CA'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">{'3PL Warehousing\n'+ 
											 'Distribution\n'+
											 'Cross Docking\n'+
											 'Transloading\n'+
											 'Palletized, Bulk o Heavy Loads\n'+
											 'FLT & LTL\n'+
											 'Inventory Control\n'+
											 '6,000 sq ft Cold Storage\n'+
											 'Truck & Trailer Yard\n'+
											 'Us Customs Brokerage\n'+
											 'C-TPAT Certified\n'+
											 'Bonded Warehouse Coming Soon!\n'
												}</p>
				
				<Link href="" className="btn redButtonLink">AG Warehouse <i className="fa fa-caret-right"></i></Link> 
			  </div> 
			</div>
		</Container>
	</div>
	<div className="red-haul-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">  
				<h4 className="serviceBlueH">{'HEAVY HAUL'}</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> To satisfy the needs of our customers for the transportation of heavy loads, <br/> 
											 Gutiérrez Trucking offers our heavy haul division, fully equipped to transport <b>all kinds</b> of heavy loads. 
				</p>
												
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="blue-operations-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 serviceBlock">   
				<h4 className="serviceBlueH serviceBlueHTiny"><b>Dedicated</b> Operations</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP"> We have the team and specialist to attend any of your company's special requirements in   
											 a dedicated way to maintain the supply chain as efficent as possible.<br/>
											 <br/>
											 <b>Why choose a dedicated service ?</b><br/>
											 <br/>
											  <b>Cost:</b><br/>
											 A dedicated service allows for a more precise cost control, preventing any additional operating costs.<br/>
											 <br/>
											  <b>Service:</b><br/>
											 Drivers and units dedicated specifically suited to your needs with the training your company requires.<br/>
											 <br/>
											  <b>Capacity:</b><br/>
											 Guarantee your customers delivery capacity year round.<br/>
											 <br/>
											 <b>Compatibility:</b><br/>
											 Trained staff in all our customer's internal processes making the supply chain seamless.<br/>
											 <br/>
			</p>
				 
			  </div> 
			</div>
		</Container>
	</div> 
	<div className="red-rent-background" >
		<div className="red-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">   
				<h4 className="serviceBlueH"><b>EQUIPMENT</b> RENT</h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">We put at your disposal our full equipment inventory for rent. <br/> 
											  53" Dry vans.<br/>
											  48" Flatbeds.<br/>
											  Step deck for normal weight.<br/>
											  Reefers.<br/> 
				</p> 						
			  </div> 
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			</div>
		</Container>
	</div>  
	<div className="blue-refrigerated-background" >
		<div className="blue-half-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
			  </div>
			  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">   
				<h4 className="serviceBlueH"><b>REFRIGERATED</b></h4> 
				<hr className="thinLine serviceRedLine"/>
				<p className="serviceBlueP">  Our refrigerated fleet is capable of transporting <b>any type</b> of temperature sensitive 
											  product thanks to our temperature monitoring system. As our client you can rest assured 
											  that your merchandise will be maintained at the right temperature until it reaches its destination.
				</p>
				 
			  </div> 
			</div>
		</Container>
	</div> 
  </Layout>
)
